import React, { useEffect, useReducer, useState } from "react";
import moment from "moment-timezone";
import Spin from "antd/es/spin";
import "antd/es/spin/style";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";
import Tabs from "antd/es/tabs";
import "antd/es/tabs/style";
import { useQueryClient } from "react-query";
import { Modal } from "../../atoms/modals/antd-modals";
import { Button } from "../../atoms/buttons/buttons";
import { ViewAppointmentModalHeader } from "./ViewAppointmentModalHeader";
import { usePermissions } from "../../../PermissionsProvider/PermissionsProvider";
import Restricted from "../../../PermissionsProvider/Restricted";
import DataStateHandler from "../../../utilities/DataStateHandler";
import RescheduleSpaAppointmentModal from "../../molecules/viewShift/RescheduleAppointmentModal";
import styles from "./ViewAppointmentModal.module.scss";
import AppointmentDetails from "./AppointmentDetails";
import AppointmentOffers from "./AppointmentOffers";
import useGetAppointmentsDetails from "../../../utilities/hooks/useGetAppointmentDetails";
import useGetProvidersByLocation from "../../../utilities/hooks/useGetProvidersByLocation";
import ReasonsToCancelAppointmentModal from "./ReasonsToCancelAppointmentModal";
import CancelAppointmentModal from "./CancelAppointmentModal";
import { useViewGlobal } from "../../../utilities/hooks/useView";
import RescheduleAppointmentModal from "./RescheduleAppointmentModal";
import EditTipModal from "./EditTipModal";
import ConfirmModal from "../../organisms/ConfirmModal";
import openNotification from "../../../utilities/openNotification";
import appointmentsAPI from "../../../utilities/api/appointment";
import useUserProfileData from "../../../utilities/hooks/useUserProfileData";
import { ReactComponent as WarningSvg } from "../../../static/img/icon/warning.svg";
import ModifyAppointmentModal from "./ModifyAppointmentModal";
import { get } from "lodash";
import { FIXED_DISABLED_HOURS } from "../../../utilities/constants";

export const Spinner = () => {
  return (
    <div className={styles["loader-wrapper"]}>
      <div className={"loader"}>
        <div className="spin" style={{ height: 0 }}>
          <Spin />
        </div>
      </div>
    </div>
  );
};

function ViewAppointmentModal({
  isOpen,
  onExit,
  appointmentId,
  locationId,
  businessId
}) {
  const { userData } = useUserProfileData(businessId);
  const isSootheMember = userData?.role?.isSootheMember;

  const { view } = useViewGlobal();
  const { permissions, businessPermissions } = usePermissions();

  const {
    data: appointment,
    isLoading: isAppointmentLoading,
    isError,
    error,
    refetch,
    should_show_warning,
    able_to_adjust
  } = useGetAppointmentsDetails({
    appointmentId,
  });

  const {
    providers,
    services,
    workinghours,
    isLoading: isProvidersLoading,
    timezone,
  } = useGetProvidersByLocation(locationId);

  let data = { providers, services, workinghours, timezone };

  useEffect(() => {
    if (timezone) {
      moment.tz.setDefault(timezone);
    }
  }, [timezone])
  const queryClient = useQueryClient();

  const [refresher, setRefresher] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isReasonsToCancelModalOpen, setIsReasonsToCancelModalOpen] =
    useState(false);
  const [selectedReasonForCancel, setSelectedReasonForCancel] = useState();
  const [isLoading, setIsLoading] = useState(false);
  let fixedDisabledHours = FIXED_DISABLED_HOURS;
  const [isDollarClicked, setIsDollarClicked] = useState(true);
  const [isPercentClicked, setIsPercentClicked] = useState(false);
  const [firstDisabledHours, setFirstDisabledHours] = useState([]);
  const [lastDisabledHours, setLastDisabledHours] = useState([]);
  const [availableShiftTime, setAvailableShiftTime] = useState({
    start: undefined,
    end: undefined,
  });
  const [selectDay, setSelectedDay] = useState({
    day: moment(appointment?.time?.utc).format("dddd"),
    date: moment.utc(appointment?.time?.utc),
  });
  const [loading, setLoading] = useState(false);
  const [postShiftModifyModalOpen, setPostShiftModifyModalOpen] = useState(false);
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [rescheduleSpaAppointment, setRescheduleSpaAppointment] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      isModalOpen: false,
      isModalloading: false,
      isFeeModalOpen: false,
      isFeeModalLoading: false,
      isModify: false,
    }
  );

  const [tip, setTip] = useReducer((prev, next) => ({ ...prev, ...next }), {
    isModalOpen: false,
    isAddTip: false,
    isUpdateTip: false,
    tipIndex: null,
  });

  const [confirmModal, setConfirmModal] = useReducer(
    (prev, next) => ({ ...next }),
    {
      isOpen: false,
      action: "",
    }
  );
  const [appointmentDetails, setAppointmentDetails] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    {
      bookingType: "",
      clientName: "",
      providerName: "",
      bookingTime: "",
      location: "",
      roomNumber: null,
      bookedBy: "",
    }
  );

  const [updateTipDetails, setUpdateTipDetails] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    {
      location_id: null,
      cart_id: null,
      cart_product_id: null,
      tip_id: null,
      percentage_of_original: null,
      amount: null,
    }
  );

  let workingDaysAndHours = {};
  workinghours
    ?.filter((day) => day.enabled)
    .forEach((day) => {
      workingDaysAndHours[day.weekname] = {
        start: day.from,
        end: day.till,
      };
    });

  const day = moment(appointment?.time?.utc).format("dddd");
  useEffect(() => {
    if (appointment && workinghours && workingDaysAndHours[day]) {
      const day = moment(appointment.time.utc).format("dddd");
      setSelectedDay({
        ...selectDay,
        date: moment.utc(appointment?.time?.utc),
        day,
      });
      const { start, end } = workingDaysAndHours[day];
      setAvailableShiftTime({
        start: moment(start, "hh:mm"),
        end: moment(end, "hh:mm"),
      });
    }
  }, [isAppointmentLoading, appointment]);

  const onModify = () => {
    if (appointment.is_staffing || appointment.is_corporate) {
      setRescheduleSpaAppointment({
        isModalOpen: true,
      });
    } else setIsRescheduleModalOpen(true);
  };
  const onPostShiftModify = () => {
    setPostShiftModifyModalOpen(true);
  };

  const onCancel = () => {
    setIsCancelModalOpen(true);
  };
  const onExitConfirmModal = () => setConfirmModal({ isOpen: false });
  const handleUpdateAppointmentTip = () => {
    setLoading(true);
    appointmentsAPI
      .updateTip(updateTipDetails)
      .then((res) => {
        setLoading(false);
        setConfirmModal({ isOpen: false });
        setTip({ isModalOpen: false });
        queryClient.invalidateQueries("getAppointmentDetails");
        queryClient.invalidateQueries("appointments");
        openNotification("Success", "Tip successfully saved.", "success");
      })
      .catch(({ response }) => {
        setLoading(false);
        console.log(response.data.data.errors[0].message);
        openNotification("Error", response.data.data.errors[0].message, "error");
      });
  };

  const handleAddAppointmentTip = () => {
    setLoading(true);
    appointmentsAPI
      .addTip(updateTipDetails)
      .then((res) => {
        setLoading(false);
        setConfirmModal({ isOpen: false });
        setTip({ isModalOpen: false });
        queryClient.invalidateQueries("getAppointmentDetails");
        queryClient.invalidateQueries("appointments");
        openNotification("Success", "Tip successfully saved.", "success");
      })
      .catch(({ response }) => {
        setLoading(false);
        openNotification("Error", response.data.data[0].message, "error");
      });
  };
  const items = [

    {
      label: "Offers",
      key: `offers-tab-${appointment?.appointment_number}`,
      children: (
        <Restricted
          to="view"
          resource="appointment"
          fallback={
            <div>
              You do not currently have the authorization to access/view the
              properties of this business.
            </div>
          }
        >
          <AppointmentOffers
            data={data}
            appointment={appointment}
            should_show_warning={should_show_warning}
            able_to_adjust={able_to_adjust}
            refetch={refetch}
            setIsLoading={setIsLoading}
            workingDaysAndHours={workingDaysAndHours}
            day={day}
            selectDay={selectDay}
            availableShiftTime={availableShiftTime}
            lastDisabledHours={lastDisabledHours}
            fixedDisabledHours={fixedDisabledHours}
            firstDisabledHours={firstDisabledHours}
            setTip={setTip}
            setAppointmentDetails={setAppointmentDetails}
            setUpdateTipDetails={setUpdateTipDetails}
            isLoading={isLoading}
            isSootheMember={isSootheMember}
          />
        </Restricted>
      ),
    },
    {
      label: "Details",
      key: `details-tab-${appointment?.appointment_number}`,
      children: (
        <Restricted
          to="view"
          resource="appointment"
          fallback={
            <div>
              You do not currently have the authorization to access/view the
              overview of this business.
            </div>
          }
        >
          <AppointmentDetails
            appointment={appointment}
            refetch={refetch}
            able_to_adjust={able_to_adjust}
          />
        </Restricted>
      ),
    },
  ];
  let showActions =
    !isAppointmentLoading &&
    appointment?.status !== "complete" &&
    appointment?.status !== "completed" &&
    appointment?.status !== "reviewed" &&
    appointment?.status !== "cancelled";

  return (
    <>
      <Modal
        wrapClassName={styles["modal-container"]}
        width={800}
        title={
          !isAppointmentLoading &&
            !isProvidersLoading ? (
            <ViewAppointmentModalHeader
              refetch={async () => {
                setRefresher(true);
                const respp = await refetch();
                setRefresher(respp?.isLoading);
              }}
              status={appointment?.status}
              appointmentNumber={appointment?.appointment_number}
              needsAttention={appointment?.needs_attention}
              partiallyFilled={appointment?.partially_filled}
            />
          ) : null
        }
        visible={isOpen}
        footer={should_show_warning && !able_to_adjust ?
          <div className="warning-yellow align-center-v text-left">
            <div><WarningSvg size={20} className="mr-8 feather" /></div>
            <div>Appointments cannot be edited after 9:00 PM PST the following day of completed appointment.</div>
          </div>
          : !isAppointmentLoading && !isProvidersLoading && !refresher ? [(
            <div className="view-appt-footer" key={`footer-of-view-apt-modal-${appointment?.appointment_number}`}>
              {isAppointmentLoading || isError ? null : (
                <div>
                  {appointment?.status !== "cancelled" && (
                    <Restricted
                      both={appointment?.status === "completed" ? false : view === "admin" ? false : true}
                      to={appointment?.status === "completed" ?
                        `post_shift_cancel_appointment_${appointment?.b2b_type === "corporate" ? "corporate" : "staffing"}`
                        : "cancel"}
                      resource={appointment.b2b_type === "corporate" ? "corporate" : "shift"}
                      fallback={<></>}
                    >
                      <Button
                        className="mr-10"
                        size="small"
                        onClick={onCancel}
                        type="danger"
                        disabled={
                          (appointment.is_corporate
                            ? !businessPermissions?.corporate?.includes("cancel") || !permissions?.corporate?.includes("cancel")
                            :
                            appointment.is_staffing
                              ? !businessPermissions?.shift?.includes("cancel") || !permissions?.shift?.includes("cancel")
                              : !permissions?.appointment?.includes("cancel"))
                          ||
                          ((appointment.b2b_type === "corporate" || appointment.b2b_type === "staffing")
                            ? appointment?.status === "cancelled"
                            : !showActions)
                          ||
                          !onCancel
                        }
                        outlined={true}
                        key={`cancel-btn-${appointment?.appointment_number}`}
                      >
                        Cancel this appointment
                      </Button>
                    </Restricted>
                  )}
                  {appointment?.status !== "cancelled" &&
                    appointment?.status !== "completed" && (
                      <Restricted
                        both={view === "admin" ? false : true}
                        to="reschedule"
                        resource={appointment.b2b_type === "corporate" ? "corporate" : "shift"}
                        fallback={<></>}
                      >
                        <Button
                          key={`reschedule-btn-${appointment?.appointment_number}`}
                          size="small"
                          onClick={onModify}
                          type="sootheButton"
                          disabled={
                            (appointment.is_corporate
                              ? !businessPermissions?.corporate?.includes("reschedule") || !permissions?.corporate?.includes("reschedule")
                              : appointment.is_staffing
                                ? !businessPermissions?.shift?.includes("reschedule") || !permissions?.shift?.includes("reschedule")
                                : !permissions?.appointment?.includes(
                                  "reschedule"
                                )) || !showActions
                          }
                        >
                          Reschedule this appointment
                        </Button>
                      </Restricted>
                    )}
                  {appointment?.status === "completed" && able_to_adjust && <Restricted
                    both={false}
                    to={`post_shift_modify_appointment_time_${appointment?.b2b_type === "corporate" ? "corporate" : "staffing"}`}
                    resource={appointment.b2b_type === "corporate" ? "corporate" : "shift"}
                    fallback={<></>}
                  >
                    <Button
                      key={`modify-btn-${appointment?.appointment_number}`}
                      size="small"
                      onClick={onPostShiftModify}
                      type="sootheButton"
                      disabled={!showActions && !able_to_adjust}
                    >
                      Modify appointment
                    </Button>
                  </Restricted>}
                </div>
              )}
            </div>
          )] : null}
        onCancel={onExit}
        centered
      >
        <DataStateHandler
          style={{ height: "75vh" }}
          isLoading={
            isAppointmentLoading || isProvidersLoading || refresher
          }
          loadingComponent={<Skeleton active paragraph={{ rows: 19 }} />}
          isError={isError}
          error={error}
          data={appointment}
        >
          {!isAppointmentLoading && !isProvidersLoading && (
            <>
              <div>
                <Tabs
                  className={styles["tabs"]}
                  size="small"
                  tabBarGutter={15}
                  items={items}
                />
              </div>
            </>
          )}
        </DataStateHandler>
        {(isLoading || refresher) && <Spinner />}
      </Modal>
      {isRescheduleModalOpen && (
        <RescheduleAppointmentModal
          appointmentId={appointmentId}
          locationId={locationId}
          businessId={businessId}
          isOpen={isRescheduleModalOpen}
          loading={loading}
          setLoading={setLoading}
          onExit={() => setIsRescheduleModalOpen(false)}
        />
      )}
      {rescheduleSpaAppointment.isModalOpen && (
        <RescheduleSpaAppointmentModal
          disabled={true}
          appointmentId={appointmentId}
          locationId={locationId}
          businessId={businessId}
          isOpen={rescheduleSpaAppointment.isModalOpen}
          loading={rescheduleSpaAppointment.isModalLoading}
          setLoading={setLoading}
          rescheduleSpaAppointment={rescheduleSpaAppointment}
          setRescheduleSpaAppointment={setRescheduleSpaAppointment}
          onExit={() => {
            setRescheduleSpaAppointment({
              isModalOpen: false,
              isFeeModalOpen: false,
            });
          }}
        />
      )}
      {isCancelModalOpen && (
        <CancelAppointmentModal
          isOpen={isCancelModalOpen}
          appointmentId={appointmentId}
          onExit={() => setIsCancelModalOpen(false)}
          onCancel={() => {
            setIsReasonsToCancelModalOpen(true);
            setIsCancelModalOpen(false);
          }}
        />
      )}
      {isReasonsToCancelModalOpen && (
        <ReasonsToCancelAppointmentModal
          loading={loading}
          setLoading={setLoading}
          isOpen={isReasonsToCancelModalOpen}
          appointmentId={appointmentId}
          businessId={businessId}
          onExit={() => {
            setIsReasonsToCancelModalOpen(false);
            onExit();
          }}
          selectedReasonForCancel={selectedReasonForCancel}
          setSelectedReasonForCancel={setSelectedReasonForCancel}
        />
      )}
      {tip.isModalOpen && (
        <EditTipModal
          isOpen={tip.isModalOpen}
          selectedAppointment={appointment}
          appointmentDetails={appointmentDetails}
          setUpdateTipDetails={setUpdateTipDetails}
          updateTipDetails={updateTipDetails}
          onExit={() => setTip({ isModalOpen: false })}
          loading={loading}
          tip={tip}
          setTip={setTip}
          isDollarClicked={isDollarClicked}
          isPercentClicked={isPercentClicked}
          setIsDollarClicked={setIsDollarClicked}
          setIsPercentClicked={setIsPercentClicked}
          setConfirmModal={setConfirmModal}
          setLoading={setLoading}
          isSootheMember={isSootheMember}
          should_show_warning={should_show_warning}
        />
      )}
      {postShiftModifyModalOpen && (
        <ModifyAppointmentModal
          disabled={true}
          appointmentId={appointmentId}
          locationId={locationId}
          businessId={businessId}
          isOpen={postShiftModifyModalOpen}
          onExit={() => {
            setPostShiftModifyModalOpen(false);
          }}
          isSootheMember={isSootheMember}
          loading={loading}
          setLoading={setLoading}
        />
      )}

      {confirmModal.isOpen && (
        <ConfirmModal
          title={`Confirm your tip for ${appointmentDetails.providerName}`}
          text={`Your tip of $${(isDollarClicked
            ? updateTipDetails.amount ?? 0
            : updateTipDetails.percentage_of_original *
            parseFloat(appointment.cart_products[tip.tipIndex].charge_amount)
          )?.toFixed(2)} will be paid out to ${appointmentDetails.providerName
            } today after the shift is complete. This action can't be undone.`}
          isClosabel
          onCancel={() => {
            onExitConfirmModal();
            setTip({ isModalOpen: true });
          }}
          buttons={[
            {
              className: styles["confirm-Modal-canecl-btn"],
              size: "small",
              type: "primary",
              outlined: true,
              text: "No, go back now",
              onClick: () => {
                onExitConfirmModal();
                setTip({ isModalOpen: true });
              },
            },
            {
              size: "small",
              type: "primary",
              loading,
              text: "Yes, submit tip",
              onClick: (e) => {
                return (tip.isAddTip && !tip.isUpdateTip) || (able_to_adjust && get(appointment, `cart_products.${tip.tipIndex}.tip_amount`, 0) == null)
                  ? handleAddAppointmentTip(e)
                  : (tip.isUpdateTip && !tip.isAddTip) || (able_to_adjust && get(appointment, `cart_products.${tip.tipIndex}.tip_amount`, 0) != null)
                    ? handleUpdateAppointmentTip(e)
                    : ""
              },
            },
          ]}
          isOpen={confirmModal.isOpen}
        />
      )}
    </>
  );
}

export default ViewAppointmentModal;
